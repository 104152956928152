@charset "utf-8"

@import "../../styles/lib/variables.sass"
@import "../../styles/lib/mixins.sass"












































.article-page
  .head
    position: relative
    .image
      margin-bottom: m(2)
    h1
      position: absolute
      width: 80%
      top: 50%
      left: 10%
      transform: translateY(-50%)
      text-align: center
      +font('bold', 'jumbo')
      color: $white
  .author
    +font('bold')

+sm
  .article-page
    .head
      h1
        +font('bold', 'bigger')

+xs
  .article-page
    .head
      h1
        position: relative
        width: 100%
        top: auto
        left: auto
        transform: none
        text-align: left
        color: $text-color
